import React from 'react';

function Baby() {
  return (
    <svg
      width="24"
      height="24"
      className="fill-current"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14.5 11.75a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5zM9.5 11.75a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5z" />
      <path d="M22.94 12.66c.04-.21.06-.43.06-.66 0-.23-.02-.45-.06-.66a4.008 4.008 0 00-2.81-3.17 9.114 9.114 0 00-2.19-2.91A8.885 8.885 0 0012 3c-2.28 0-4.36.85-5.94 2.26-.92.81-1.67 1.8-2.19 2.91a3.994 3.994 0 00-2.81 3.17c-.04.21-.06.43-.06.66 0 .23.02.45.06.66a4.008 4.008 0 002.81 3.17 8.977 8.977 0 002.17 2.89A8.885 8.885 0 0012 21c2.29 0 4.38-.86 5.97-2.28.9-.8 1.65-1.79 2.17-2.89a3.998 3.998 0 002.8-3.17zM19 14c-.1 0-.19-.02-.29-.03-.2.67-.49 1.29-.86 1.86A6.976 6.976 0 0112 19c-2.45 0-4.6-1.26-5.85-3.17-.37-.57-.66-1.19-.86-1.86-.1.01-.19.03-.29.03-1.1 0-2-.9-2-2s.9-2 2-2c.1 0 .19.02.29.03.2-.67.49-1.29.86-1.86A6.976 6.976 0 0112 5c2.45 0 4.6 1.26 5.85 3.17.37.57.66 1.19.86 1.86.1-.01.19-.03.29-.03 1.1 0 2 .9 2 2s-.9 2-2 2zM7.5 14c.76 1.77 2.49 3 4.5 3s3.74-1.23 4.5-3h-9z" />
    </svg>
  );
}

export default Baby;
