import React from 'react';

function Lock() {
  return (
    <svg
      width="18"
      height="22"
      className="fill-current"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M15.266 7.345h-1.044v-2.09A5.224 5.224 0 009 .036a5.224 5.224 0 00-5.222 5.22v2.09H2.734c-1.149 0-2.089.94-2.089 2.088v10.444c0 1.149.94 2.088 2.089 2.088h12.532c1.149 0 2.089-.94 2.089-2.088V9.433c0-1.148-.94-2.088-2.089-2.088zM9 16.744a2.095 2.095 0 01-2.089-2.089c0-1.149.94-2.089 2.09-2.089 1.148 0 2.088.94 2.088 2.09 0 1.148-.94 2.088-2.089 2.088zm3.238-9.4H5.763V5.257A3.24 3.24 0 019 2.018a3.24 3.24 0 013.238 3.238v2.089z" />
    </svg>
  );
}

export default Lock;
